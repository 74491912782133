const meta = {
  absolute: 'Absolute',
  'amazon-kinesis-firehose': 'Amazon Kinesis Firehose',
  'amazon-kinesis-stream': 'Amazon Kinesis Stream Input',
  'azure-event-grid': 'Azure Event Grid',
  'azure-event-hub': 'Azure Event Hub',
  bash: 'Bash Command',
  cloudflare: 'Cloudflare LogPush',
  'confluent-cloud': 'Confluent Cloud',
  'datadog-agent': 'Datadog Agent',
  datagen: 'Datagen',
  dummy: 'Dummy logs, metrics, and traces',
  'dummy-http': 'Dummy HTTP',
  'elasticsearch-http': 'Elasticsearch HTTP',
  'fluent-bit': 'Fluent Bit',
  fluentd: 'Fluentd',
  forward: 'Forward',
  'google-cloud-pubsub': 'Google Cloud PubSub Input',
  'google-workspace-audit': 'Google Workspace Audit',
  http: 'HTTP',
  'http-api-collector': 'HTTP API collector',
  intune: 'Microsoft Intune - Audit event',
  kafka: 'Kafka',
  kubernetes: 'Kubernetes',
  'mandiant-asm': 'Mandiant ASM',
  'mock-data': 'Mock Data',
  okta: 'Okta System Logs Collector',
  opentelemetry: 'OpenTelemetry',
  'prometheus-remote': 'Prometheus Remote Write',
  'prometheus-scrape': 'Prometheus Scrape',
  'amazon-s3': 'S3 Input (One Time)',
  's3-sqs': 'S3 Input (SQS)',
  'signal-sciences': 'Signal Sciences',
  slack: 'Slack',
  'splunk-hec': 'Splunk HEC',
  'splunk-uf': 'Splunk UF',
  sqldb: 'SQL DB Input',
  syslog: 'Syslog',
  tcp: 'TCP',
  telegraf: 'Telegraf',
  vectram365: 'Vectra M365 - Incident Detection',
  vercel: 'Vercel Logs',
};

export default meta;
